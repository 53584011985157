// this is used in places that we don't actually want to render a button .. ie a <label /> for an
// input[type=file]
import styled from '@emotion/styled'

import { PropTypes } from '@components/button/button'

import Spinner from './spinner'
import { fetchBackgroundColor, fetchBorderColor } from './style-helpers'
import { PRIMARY } from './types'

const Button = styled.div<PropTypes>`
  box-sizing: border-box;
  background-color: ${fetchBackgroundColor};
  position: relative;
  padding: 1.4rem;
  border-radius: 0.2rem;
  color: #fff;
  cursor: pointer;
  min-width: 5rem;
  font-size: 1.2rem;
  font-family: 'Open Sans';
  font-weight: 400;
  letter-spacing: 0.03em;
  outline: none;
  width: 100%;
  text-transform: uppercase;
  white-space: nowrap;
  border: 0.1rem solid ${fetchBorderColor};
  text-align: center;

  &:hover {
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.2);
    opacity: 0.9;
  }
`

function StyleOnlyComponent({ buttonType = PRIMARY, loading = false, children, ...otherProps }: PropTypes) {
  const content = loading ? <Spinner /> : children

  return (
    <Button buttonType={buttonType} {...otherProps}>
      {content}
    </Button>
  )
}

export default StyleOnlyComponent
