import { PropTypes } from '@components/button/button'
import Colors from 'microcomponents/colors'

import { TEXT, TRANSPARENT } from './types'

export const colorMap = {
  CBD: Colors.cbd[1],
  HYBRID: Colors.hybrid[1],
  SATIVA: Colors.sativa[1],
  INDICA: Colors.indica[1],
  DANGER: Colors.danger[1],
  ACCESSORY: Colors.accessory[1],
  PRIMARY: Colors.primary[1],
  TRANSPARENT: 'transparent',
  TEXT: 'transparent'
} as const

export const CUSTOM_PADDING = {
  pill: '12px',
  supersize: '1.055rem 1.4rem',
  loading: '1.05rem 1.4rem',
  default: '1.4rem'
}

export function getFontFamily({ active, pill }: PropTypes): string {
  if (pill) {
    return active ? 'Suisse Intl Mono Bold' : 'Suisse Intl Mono Regular'
  }
  return 'Open Sans'
}

export function getTextColor({ buttonType, inverted, pill, active }: PropTypes): string {
  if (buttonType === TEXT) {
    return Colors.primary[1]
  }

  if (inverted || (pill && !active)) {
    return colorMap[buttonType]
  }

  return 'white'
}

export function fetchBackgroundColor({ buttonType, inverted, pill, active }: PropTypes): string {
  return inverted || (pill && !active) ? 'white' : colorMap[buttonType]
}

export function fetchDisabledBackgroundColor({ buttonType }: PropTypes): string {
  return buttonType === TRANSPARENT ? colorMap.TRANSPARENT : Colors.accessory['3']
}

export function fetchBorderColor({ buttonType, inverted, pill }: PropTypes): string {
  if (buttonType === TRANSPARENT) return 'white'

  return inverted || pill ? colorMap[buttonType] : 'transparent'
}

export function getCustomPadding({ pill, supersize, loading }: PropTypes): string {
  if (pill) return CUSTOM_PADDING.pill
  if (supersize && loading) return CUSTOM_PADDING.supersize
  if (loading) return CUSTOM_PADDING.loading
  return CUSTOM_PADDING.default
}
