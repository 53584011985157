import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'

import styled from '@emotion/styled'

import Colors from 'microcomponents/colors'

import Badge from './badge'
import Spinner from './spinner'
import {
  fetchBackgroundColor,
  fetchBorderColor,
  fetchDisabledBackgroundColor,
  getCustomPadding,
  getFontFamily,
  getTextColor
} from './style-helpers'
import { PRIMARY, TEXT } from './types'

export type PropTypes = {
  buttonType?: string
  onClick?: (_) => void
  disabled?: boolean
  loading?: boolean
  inverted?: boolean
  supersize?: boolean
  pill?: boolean
  active?: boolean
  children?: any
  componentStyle?: object
  notification?: string | number
  as?: any
  htmlFor?: any
}

const Button = styled.button<PropTypes>`
  line-height: ${({ pill }) => (pill ? '22px' : '1.4rem')};
  padding: ${getCustomPadding};
  font-size: ${({ supersize }) => (supersize ? '1.6rem' : '1.2rem')};
  font-weight: ${({ supersize }) => (supersize ? '900' : '400')};
  width: ${({ pill }) => (pill ? '' : '100%')};
  text-transform: uppercase;

  background-color: ${fetchBackgroundColor};
  color: ${getTextColor};
  border: 0.1rem solid ${fetchBorderColor};

  border-radius: ${({ pill }) => (pill ? '0' : '0.2rem')};
  position: relative;
  cursor: pointer;
  min-width: 5rem;
  font-family: '${getFontFamily}';
  letter-spacing: 0.03em;
  outline: none;
  white-space: nowrap;
  text-align: center;

  &:hover {
    box-shadow: ${({ buttonType }) => (buttonType === TEXT ? 'none' : '0 0.4rem 0.8rem rgba(0,0,0,.2)')};
    opacity: 0.9;
  }

  &[disabled],
  &[disabled]:hover {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
    background-color: ${fetchDisabledBackgroundColor};
    color: ${Colors.accessory['2']};
    border: 1px solid ${Colors.accessory['3']};
  }
`

function ButtonComponent(
  props: PropTypes & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
) {
  const { children, componentStyle, loading, notification, buttonType = PRIMARY } = props
  return (
    <Button {...props} style={{ ...componentStyle }} buttonType={buttonType} loading={loading}>
      {notification && <Badge count={notification} />}
      {loading ? <Spinner /> : children}
    </Button>
  )
}

export default ButtonComponent
